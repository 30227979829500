import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { PopupModal } from 'react-calendly';
import ReactGA from 'react-ga4';

export const BookDemoButton = ({variant, classSet, size, buttonText}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
      setIsOpen(true);
      ReactGA.event({
        category: 'Button',
        action: 'Clicked',
        label: 'Book Demo Button'
      });
    }
    

  return (
    <>
        <Button variant={variant ? variant : 'primary'} size={size} onClick={handleClick} className={classSet}>{buttonText ? <><i className="bi bi-calendar-plus me-2"></i>{buttonText}</> : <><i className="bi bi-calendar-plus me-2"></i>Schedule a Demo</>}</Button>
                <PopupModal
              url="https://calendly.com/teacherflow"
              onModalClose={() => setIsOpen(false)}
              open={isOpen}
              rootElement={document.getElementById("root")}
            />
    </>
  );
}
