import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { BookDemoButton } from "./bookDemoButton";

function MyNavbar() {
    const [visible, setVisible] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const billboard = document.getElementById('header_textual');
          if (billboard) {
            const rect = billboard.getBoundingClientRect();
            if (rect.top <= 0) {
              setVisible(true);
            } else {
              setVisible(false);
              setIsExpanded(false); // Collapse the navbar when scrolling up
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
      <Navbar expanded={isExpanded} data-bs-theme="dark" fixed="top" expand="md" id="my-navbar" className={`m-auto w-100 w-md-50 p-0 pt-md-2 px-2 navbar ${visible ? 'visible' : ''}`}>
        <Container id="navbar-block" className="bg_gradient text-light rounded-5 p-2 ps-5 shadow">
          <Navbar.Brand className="me-5"><i className="bi bi-water me-2"></i>TeacherFlow</Navbar.Brand>
        {/*
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIsExpanded(!isExpanded)} />
        <Navbar.Collapse id="responsive-navbar-nav" className="mb-2 mb-md-0">
        <Nav className="me-auto">
          <Nav.Item>
            <Nav.Link href="#footer">Footer</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link href="/home">Do More</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link href="/home">Be More</Nav.Link>
        </Nav.Item>
        </Nav>
        </Navbar.Collapse>*/}
        <Nav.Item className="text-center">
        <BookDemoButton variant="light" classSet="rounded-5" />
        </Nav.Item>
        </Container>
      </Navbar>
  );
}

export default MyNavbar;