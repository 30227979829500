import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { BookDemoButton } from './bookDemoButton';

export const BillboardTopper = () => {
  return (
    <>
    <div id="billboard-entry" className="w-100 d-flex px-0 px-md-5 justify-content-center align-items-center" style={{minHeight: '70vh'}}>
      {/*<div className="d-flex align-items-center w-100">*/}
        <Row className="w-100">
            <Col xs={12} md={8} className="p-4 pb-3 p-md-5">
                <h3><Badge bg="none" className="tf_font bg_gradient px-5"><i className="bi bi-water me-2"></i><span className="fw-lighter">TeacherFlow</span></Badge></h3>
                <p id="header_textual" className="tf_font pb-1 gradient_text" style={{fontSize: '3rem'}}>Supercharge <b>your</b> Supply Teaching Agency.</p>
                <p className="lead text-secondary">TeacherFlow automates the manual yet critical work for your supply teaching agency.
                    <br />Allowing you to concentrate on the strategic, human aspects of your role.
                </p>
            </Col> 
            <Col xs={12} md={4} className="d-flex p-5 pt-0 p-md-5 align-items-center">
                <BookDemoButton classSet="w-100 rounded-5" />
            </Col>
          </Row>
    </div>
    </>
  );
}
