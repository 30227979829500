import React from 'react';
import { Badge } from 'react-bootstrap';

export function LegalHolder( {children} ) {
  return (
    <div className="m-4">
    <h3 className="mb-4"><Badge bg="none" className="tf_font bg_gradient px-5"><i className="bi bi-water me-2"></i><span className="fw-lighter">TeacherFlow</span></Badge></h3>
        {children}
    </div>
  );
};