import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { BookDemoButton } from './bookDemoButton';

export const FinalCall = () => {
  return (
    <>

    <Container fluid className="gradient_bg p-5">
        <Row className="justify-content-center text-center">
            <Col xs={12} className="text-secondary rounded-3 px-0 px-md-5">
            <p className="tf_font text-light" style={{fontSize: '2rem'}}>Are you ready to supercharge <b>your</b> Supply Teaching Agency?</p>
            </Col>
            <Col xs={10} md={5}>
            <BookDemoButton classSet="w-100 rounded-5" buttonText="Schedule a Demo Today" />
            </Col>
        </Row>
    </Container>
    </>
  );
}

