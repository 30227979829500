import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

export const FeaturesSlip = () => {
  return (
    <>

    <Container fluid="md" className="mb-5">
        <Row className="g-2 justify-content-start justify-content-md-center">
            <Col xs={6} md={2} className="text-secondary rounded-3 p-2">
                <h1 className="rainbow_text"><i className="bi bi-paint-bucket"></i></h1>
                <p className="m-0"><span className="rainbow_text">Customise</span> your dashboard.</p>
                <p className="text-body-tertiary d-block m-0" style={{fontSize: '.8em'}}>Your branding, your colour pallette, your rules!</p>
            </Col>

            <Col xs={6} md={2} className="text-secondary rounded-3 p-2">
                <h1 className="text-danger"><i className="bi bi-arrow-left-right"></i></h1>
                <p className="m-0">Manual work <span className="text-danger">Automated.</span></p>
                <p className="text-body-tertiary d-block m-0" style={{fontSize: '.8em'}}>Giving your staff time to focus on the things that matter.</p>
            </Col>

            <Col xs={6} md={2} className="text-secondary rounded-3 p-2">
                <h1 className="text-warning"><i className="bi bi-search"></i></h1>
                <p className="m-0"><span className="text-warning">Track & Manage</span> each interaction.</p>
                <p className="text-body-tertiary d-block m-0" style={{fontSize: '.8em'}}>Seach tools, tracking and more built in for your full oversight.</p>
            </Col>

            <Col xs={6} md={2} className="text-secondary rounded-3 p-2">
                <h1 className="text-primary"><i className="bi bi-person-fill-lock"></i></h1>
                <p className="m-0"><span className="text-primary">Security</span> built in.</p>
                <p className="text-body-tertiary d-block m-0" style={{fontSize: '.8em'}}>Built using the latest highly secure technology.</p>
            </Col>

            <Col xs={6} md={2} className="text-secondary rounded-3 p-2">
                <h1 className="text-success"><i className="bi bi-shield-fill-check"></i></h1>
                <p className="m-0">Fully <span className="text-success">GDPR Compliant.</span></p>
                <p className="text-body-tertiary d-block m-0" style={{fontSize: '.8em'}}>All data is collected, stored and managed inline with EU guidlines.</p>
            </Col>
        </Row>
    </Container>
    </>
  );
}

