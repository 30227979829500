import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MyNavbar from './myNavbar';
import { BillboardTopper } from './billboardTopper';
import { Footer } from './footer';
import { FeaturesSlip } from './featuresSlip';
import { FinalCall } from './finalCall';
import { WideDisplayStripe } from './wideDisplayStripe';
import { Badge } from 'react-bootstrap';
import { Terms } from './terms';
import { LegalHolder } from './legalHolder';
import { PrivacyPolicy } from './privacyPolicy';

function App() {
  const renderMainContent = () => (
    <>
    <main>
    <BillboardTopper />
    <FeaturesSlip />
    <MyNavbar />

    <WideDisplayStripe id="2" noImg={true} bg_colour="bg-light" headline={<><i class="text-warning bi bi-lightning-charge me-2"></i>Suitable Supply in a Few Clicks</>}>
    <p>Schools can request supply, view and send requests to matching candidates almost instantly.</p>
    <p>Candidates are automatically displayed based on supply needs and geographical location.</p>
    <p>Candiates will be automaitcally sent all the important information, including a Google Maps integration for their precise and easy route planning.</p>
    </WideDisplayStripe>

    <WideDisplayStripe id="1" noImg={true} bg_colour="bg-body" img_right={true} headline={<><span class="rainbow_text"><i className="bi bi-paint-bucket me-2"></i></span>Fully Customisable</>}>
    <p>The portal offers a white label solution to your supply agency needs.</p>
    <p>Customisable easily and instantly by your Agency. Names, Logos and brand colours can be updated at the touch of a button.</p>
    <p>Your bespoke portal can easily be self hosted on your own subdomain eg. <Badge as='p' bg="secondary">portal.your-agencys-website.com</Badge></p>
    </WideDisplayStripe>


    <WideDisplayStripe id="3" noImg={true} bg_colour="bg-light" img_right={true} headline={<><i className="text-primary bi bi-person-fill-lock me-2"></i>Security Built In</>}>
    <p>Built using the latest highly secure technology.</p>
    <p>The front end uses tech created by Meta and used by the likes of Netflix, BBC, Uber and Airbnb.</p>
    <p>Meanwhile the processes and database are securely processed with AWS servers created by Amazon.</p>
      </WideDisplayStripe>

    <FinalCall />

    <Footer />
    </main>
    </>
  );

  return (
    <Router>
    <Routes>
      <Route path="/*" element={renderMainContent()} />
      <Route path="/privacypolicy" element={<LegalHolder><PrivacyPolicy /></LegalHolder>} />
      <Route path="/terms" element={<LegalHolder><Terms/></LegalHolder>} />
    </Routes>
    </Router>
  );
}

export default App;
