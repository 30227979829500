import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

export const WideDisplayStripe = ({ bg_colour, img_right, headline, children, imgSrc, noImg }) => {
  return (
    <>
    <div className={bg_colour}>
    <Container fluid="lg">
        <Row className="justify-content-center">
            {!noImg && (<Col xs={12} md={6} className={`order-0 ${img_right && 'order-md-1'}`}>
            <img className="w-100 p-3 pt-4 p-md-5" src={imgSrc} alt="demo display" />
            </Col>)}
            <Col xs={12} md={noImg ? 8 : 6} className={`my-0 my-md-5 p-5 p-md-0 ${img_right && !noImg && 'ps-md-5'}`}>
            <h1 className="tf_font">{headline}</h1>
            <p className="fw-lighter">{children}</p>
            </Col>
        </Row>
    </Container>
    </div>
    </>
  );
}

