import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Footer = () => {

  return (
    <div id="footer" className="d-flex flex-column w-100 align-items-center justify-content-center text-secondary p-4 text-center">
      <p className="m-0"><small>&copy; Copyright 2024 TeacherFlow.</small></p>
        <p className="m-0"><small>All rights reserved.</small></p>
        <p className="m-0"><a href="/privacypolicy" target='_blank' rel="noreferrer"><small>Privacy Policy</small></a>
        <span className="mx-2">|</span>
        <a href="/terms" target='_blank' rel="noreferrer"><small>Terms of Service</small></a></p>
    </div>
  );
}
